import '../scss/main.scss';

import { Startup } from './modules/startup.js';
import { Navigation } from './modules/navigation.js';
import { Parallax } from './modules/parallax.js';
import { Team } from './modules/team.js';
import { Project } from './modules/project.js';

//import { Svg } from './modules/svg.js';

//import { Slider } from './modules/slider.js';
//import { Scroll } from './modules/scroll.js';

Startup.init();
Navigation.init();
Parallax.init();
Team.init();
Project.init();

//Svg.init();

//Slider.init();
//Scroll.init();
