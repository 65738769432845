import $ from 'jquery';
import '../lib/jquery.scrollspy.js';

export var Scroll = {
    name: 'Scroll',
    el: $('.scrollspy'),
    init: function () {
        if (this.el.length) {
            this.setup();
        }
    },
    setup: function () {
        var _this = this;
        this.el.scrollspy({
            onEnter: function (element, position) {
                $(element).addClass('enter');
            },
            onLeave: function (element, position) {
                //$(element).removeClass('enter');
            }
        });

    }
}
