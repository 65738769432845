import Rellax from 'rellax';
import $ from 'jquery'
// node_modules/rellax/rellax.min.js'

export var Parallax = {
    name: 'Parallax',
    selector: '.element-parallax',
    init: function() {

        if($(this.selector).length) {
            var rellax = new Rellax(this.selector, {
                /*speed: -2,
                 center: false,
                 round: true*/
            });
        }


    },
}
