import $ from 'jquery';

export var Team = {
    name: 'Team',
    el: $('.team-wall'),
    items: $('.team-member'),
    init: function() {

        this.el.on('click', '.trigger', function(e) {
            e.stopPropagation();
            var _self = $(this);
            var parent = _self.parent();
            if(parent.hasClass('active')) {
                parent.removeClass('active');
            } else {
                parent.addClass('active');
            }
        });
        this.el.on('click', '.team-member', function(e) {
            var _self = $(this);
            if(!_self.hasClass('active')) {
                _self.addClass('active');
            }
        })

        /*this.items.each( (i, o) => {
             $(o).find('.trigger').on('click', function() {
                $(this).parent().toggleClass('active');
             })
         });*/
    },
}
