import $ from 'jquery';
import ImagePreloader from "image-preloader";
import { Slider } from './slider.js';
import { Scroll } from './scroll.js';
import Player from '@vimeo/player';

export var Startup = {
    name: 'Startup',
    body: $('body'),
    wrap: $('.body-wrap'),
    loader: $('.body-loader'),
    bar: $('.progress-bar'),
    splash: $('.splash'),
    homeHeader: $('.home-header'),
    preloader: null,
    init: function(){
        this.preloader = new ImagePreloader();
        this.setup();
    },
    setup: function () {
        var images = Array.prototype.slice.call(document.getElementsByTagName('img'));
        var loaded = 0;
        var tags = document.getElementsByTagName('*');
        var el;
        var additional = [];

        for (var i = 0, len = tags.length; i < len; i++) {
            el = tags[i];
            if (window.getComputedStyle) {
                if( document.defaultView.getComputedStyle(el, null).getPropertyValue('background-image') !== 'none' ) {
                    var background = document.defaultView.getComputedStyle(el, null).getPropertyValue('background-image');
                    var background = background.replace(/^url\(["']?/, '').replace(/["']?\)$/, '');;
                    additional.push(background);
                }
            }
        }

        var imagesAll = images.concat(additional);
        var total = imagesAll.length;

        this.preloader.onProgress = (info) => {
            var completed = parseInt(100 / total * ++loaded) + '%';
            this.bar.css('width', completed);
        };

        this.preloader.preload(imagesAll)
            .then( (status) => {
                this.loaded();
                this.events();
            });
    },
    events: function () {
        $('.scroll-arrow').on('click', function(e) {
            e.preventDefault();

            var layout = $('.layout');
            var elementTop = (layout.offset().top - 120);

            $("html, body").animate({ scrollTop: elementTop }, {duration: 400, easeing: 'easeOut'});
        });
    },
    loaded: function () {
        setTimeout( () => {
            this.wrap.addClass('body-in');
            this.loader.addClass('loader-out');

            if(this.homeHeader.length) {
                setTimeout( () => {
                    this.homeHeader.addClass('show-content');
                }, 600);
            }

            var iframe = document.getElementById('home-vimeo');
            if(iframe) {
                var player = new Player(iframe);
                player.ready().then(function() {
                    Slider.init();
                    Scroll.init();
                });
            } else {
                Slider.init();
                Scroll.init();
            }

            //this.doSplash();
            //Slider.init();
            //Scroll.init();
        }, 500);
    },
    doSplash: function () {
        if(this.hasPlayed()) {
            this.hideSplash();
            return true;
        }
        if(this.splash.length) {
            this.body.addClass('no-scroll');
            this.splash.addClass('splash-active');

            setTimeout( () => {
                this.hideSplash();
            }, 3000);
        }
    },
    hideSplash: function () {
        this.body.removeClass('no-scroll');
        this.splash.addClass('splash-out');
        this.homeHeader.addClass('show-content');
        window.sessionStorage.splashPlayed =true;
    },
    hasPlayed: function () {
        if (typeof(Storage) !== "undefined") {
            return window.sessionStorage.splashPlayed ? window.sessionStorage.splashPlayed : false;
        } else {
            return false;
        }
    }
}
