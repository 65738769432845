import jQuery from 'jquery';
import $ from 'jquery';

const scrollspy = ($, window, document ) => {

    $.fn.extend({
        scrollspy: function ( options ) {

            var defaults = {
                min: 0,
                max: 0,
                mode: 'vertical',
                namespace: 'scrollspy',
                container: window,
                onEnter: options.onEnter ? options.onEnter : [],
                onLeave: options.onLeave ? options.onLeave : [],
                onTick: options.onTick ? options.onTick : []
            }

            var options = $.extend( {}, defaults, options );

            return this.each(function (i) {

                var element = this;
                var $element = $(element);
                var o = options;
                var $container = $(o.container);
                var mode = o.mode;
                var enters = 0;
                var leaves = 0;
                var inside = false;
                var windowHeight = $container.height();
                //

                var bufferTop = $element.data('buffer-top') ? $element.data('buffer-top') : 100;
                var bufferBtm = $element.data('buffer-btm') ? $element.data('buffer-btm') : 200;

                /* add listener to container */
                $container.bind('scroll.' + o.namespace, function(e){
                    animate();
                });
                $(function() {
                    animate();
                });

                function animate() {
                    var offset = $element.offset();
                    var position = {top: $(window).scrollTop(), left: $(window).scrollLeft()};
                    var xy = (mode == 'vertical') ? position.top : position.left;
                    var max = o.max;
                    var min = o.min;

                    if($.isFunction(o.min)){
                        min = o.min();
                    } else {
                        min = (offset.top - windowHeight + bufferTop);
                    }

                    if($.isFunction(o.max)){
                        max = o.max();
                    } else {
                        max = (offset.top + $element.height() - bufferBtm);
                    }

                    if(max == 0){
                        max = (mode == 'vertical') ? $container.height() : $container.outerWidth() + $(element).outerWidth();
                    }

                    //console.log(xy, min, max);
                    /* if we have reached the minimum bound but are below the max ... */
                    if(xy >= min && xy <= max){
                        /* trigger enter event */
                        if(!inside){
                            inside = true;
                            enters++;

                            /* fire enter event */
                            $(element).trigger('scrollEnter', {position: position})
                            if($.isFunction(o.onEnter)){
                                o.onEnter(element, position);
                            }

                        }

                        /* trigger tick event */
                        $(element).trigger('scrollTick', {position: position, inside: inside, enters: enters, leaves: leaves})
                        if($.isFunction(o.onTick)){
                            o.onTick(element, position, inside, enters, leaves);
                        }
                    }else{

                        if(inside){
                            inside = false;
                            leaves++;
                            /* trigger leave event */
                            $(element).trigger('scrollLeave', {position: position, leaves:leaves})

                            if($.isFunction(o.onLeave)){
                                o.onLeave(element, position);
                            }
                        }
                    }
                }

            });
        }

    })

}
scrollspy(jQuery, window, document);
