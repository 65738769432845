import $ from 'jquery';

export var Project = {
    name: 'Project',
    el: $('.project-wall'),
    init: function() {
        if(this.el.length) {
            this.playVideos();   
        }
    },
    playVideos: function () {

        //document.getElementsByTagName('video')[0].volume = 0.5;

        $('.project-video video').each(function(index,element) {
            element.volume = 0;
            element.play();
        });
    }
}
