import $ from 'jquery';
import 'slick-carousel';

export var Slider = {
    name: 'Slider',
    init: function(){
        $('.slider').slick({
            dots: true,
            arrows: true,
            nextArrow: '<button type="button" class="slick-next"></button>',
            prevArrow: '<button type="button" class="slick-prev"></button>',
            speed: 500
        });
    }
}
