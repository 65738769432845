import $ from 'jquery';

export var Navigation = {
    name: 'Navigation',
    el: $('nav.primary'),
    trigger: $('.nav-trigger'),
    init: function() {
        this.events();
    },
    events: function() {
        this.trigger.on('click', () => {
            if($('body').hasClass('menu')) {
                $('body').removeClass('menu');
            } else {
                $('body').addClass('menu');
            }
        });

        $(window).scroll(function() {
            //var scrollPosition = $('body').scrollTop();
            var scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

            if(scrollPosition > 172) {
                $('body').addClass('sticky');
            } else {
                $('body').removeClass('sticky');
            }
        });
    }
}
